import { Reviews, ReviewsItem, ReviewsList } from '@components/Reviews';
import Picture from '@components/UI/Picture';

import React from 'react';

import HomeData from './data.js';

const HomeBadges = () => {
    const { badges } = HomeData;

    return (
        <Reviews
            className='section section_home'
            mods={ { theme: 'home-badges' } }
        >
                <ReviewsList>
                    { badges.map(badge => (
                        <ReviewsItem
                            key={ badge.id }
                        >
                            <Picture
                                image={badge.image}
                                alt={badge.alt}
                                imageClassName={badge.className}
                            />
                        </ReviewsItem>
                    )) }
                </ReviewsList>
        </Reviews>
    );
};

export default HomeBadges;